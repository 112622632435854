.portfolio__container{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 2.5rem;
}

.portfolio__item{
    background: var(--color-bg);
    padding: 1.3rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
    margin-bottom: 1.2rem;
}

.portfolio__item:hover{
    border-color: var(--color-primary-variant);
    background: transparent;
}

.portfolio__item-image{
    border-radius: 1.5rem;
    overflow: hidden;

}

.portfolio__item h3{
    margin: 1.2rem 0 2rem;
}


#more{
    margin-top: 1rem;
    vertical-align: ;
}


.my-masonry-grid {
    display: -webkit-box; /* Not needed if autoprefixing */
    display: -ms-flexbox; /* Not needed if autoprefixing */
    display: flex;
    margin: 0 auto;
/* margin-left: -30px; gutter size offset */
    width: 80%;
    align-content: center;
  }
  .my-masonry-grid_column {
    padding-left: 30px; /* gutter size */
    background-clip: padding-box;
  }
  
  /* Style your items */
  .my-masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */
    background: grey;
    margin-bottom: 30px;
  }

  
@media screen and (max-width: 1024px){
   .portfolio__container{
    grid-template-columns: 1fr 1fr;
    gap: 1.2rem;
   }
}

@media screen and (max-width: 600px){
    .portfolio__container{
        grid-template-columns: 1fr;
        gap: 1rem;
       }
}