.about__container {
    padding: 2rem;
    display: grid;
    grid-template-columns: 35% 50%;
    gap: 15%;
}

.about__me {
    width: 100%;

    border-radius: 2rem;
    background: linear-gradient(45deg, transparent, var(--color-primary), transparent);
    display: grid;
    place-items: center;
}

.about__me-image {
    border-radius: 2rem;
    overflow: hidden;
    transform: rotate(10deg);
}

.about__me-image:hover {
    transform: rotate(0);
    transition: ease-in-out .2s;
}

.about__cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5rem;
}

.about_card {
    padding: 5%;
    background: var(--color-bg-variant);
    border: 1px solid transparent;
    border-radius: 1rem;
    text-align: center;
}

.about_card:hover {
    background: transparent;
    border-color: var(--color-primary-variant);
    cursor: default;
}

.about__icon {
    color: var(--color-primary);
    font-size: 1.4rem;
    margin-bottom: 1rem;
}

.about__icon h5 {
    font-size: 0.9rem;
}

.about_card small {
    font-size: 0.7rem;
    color: var(--color-light);
}

.about__content>p {
    margin-top: 1rem;
    margin: 2rem 0 2.6rem;
    color: var(--color-light)
}

#about>h5 {
    text-align: center;
}
#about>h6 {
    text-align: center;
}
#about>h2 {
    text-align: center;
}

.font_bold{
    color: white;
    font-weight: bold;
}

#credit{
    margin-bottom: 1.2rem;
}
@media screen and (max-width:1024px) {
    .about__container{
        grid-template-columns: 1fr;
        gap: 0;
    }

    .about_me{
        margin-bottom: 1rem;
        width: 50%;
        margin: 2rem auto 4rem;
    }

    .about__content>p {
        margin-top: 1rem;
    }
}

@media screen and (max-width:600px) {
    
    .about_me{
        width: 65%;
        margin: 0 auto 3rem;
    }

    .about__cards{
        margin-top: 1rem;
        grid-template-columns: 1fr 1fr;
        gap: 1rem;
    }

    .about__content{
        text-align: center;
    }

    .about__content p {
        margin: 1.5rem 0;
    }
}